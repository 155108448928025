.responsive-table {
    &__title {
        color: #fff;
        text-transform: uppercase;
        @include fontSize(30px);
        text-align: center;
        width: 100%;
        background: $color-1;
        padding: 10px 15px;
        text-shadow: 3px 1px 3px rgba(0, 0, 0, .24);
    }

    table {
        width: 100%;
        text-align: center;
        @include fontSize(18px);
        font-weight: 600;
    }

    thead {
        background: #f9efe5;
        font-weight: 700;
        color: #000;
        @include fontSize(20px);
    }

    tbody {
        background: #fff;

    }

    tr {
        &.space-tr {
            background: #efefef;

            td {
                text-align: center;
                @include fontSize(24px);
                color: #a5540a;

                i {
                    @include fontSize(13px);
                    margin: 0 5px;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    td,
    th {
        border-right: 1px dashed #afafaf;
        border-bottom: 1px solid #afafaf;

        &:first-child {
            text-align: left;
            border-left: 1px solid #afafaf;
        }

        &:last-child {
            border-right: 1px solid #afafaf;
        }
    }

    th {
        border-left: 1px solid #afafaf;
        border-right: 1px solid #afafaf;
    }

}


// Transform Table


.transform-table {
    // table {
    //     width: 750px;
    //     border-collapse: collapse;
    //     margin: 50px auto;

    //     @media (min-width: 768px) {
    //         width: 100%;
    //     }
    // }

    /* Zebra striping */
    // tr:nth-of-type(odd) {
    //     background: #eee;
    // }

    // th {
    //     background: #3498db;
    //     color: white;
    //     font-weight: bold;
    // }

    // td,
    // th {
    //     padding: 10px;
    //     border: 1px solid #ccc;
    //     text-align: left;
    //     font-size: 18px;
    // }

    /* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
    @media only screen and (max-width: 760px) // ,(min-device-width: 768px) and (max-device-width: 1024px) 

        {

        table {
            width: 100%;
        }

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
            background: #fff;

            &:nth-child(odd) {
                background: #f9efe5;
            }
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            border-left: none !important;
            border-right: none !important;
            text-align: left !important;

        }

        td[colspan="7"] {
            padding: 10px !important;
            text-align: center !important;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            /* Label the data */
            content: attr(data-column);

            color: #000;
            font-weight: bold;
        }

    }
}
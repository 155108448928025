@media (min-width: 1921px) {
    body {
        max-width: 1920px;
        left: calc((100% - 1920px)/2);
        position: relative;
    }

    .sidenav {
        left: calc((100% - 1920px)/2);
    }

    .header {
        max-width: calc(1920px - 294px);
        margin-left: calc((100% - 1920px)/2);
    }
}

@media (max-width: 1919px) {
    .footer-top .container .col {
        width: 25%;

        &:nth-child(2) {
            width: 50%;
        }
    }

    .main {
        margin-top: 128px;
    }
}

@media (max-width: 1599) {}

@media (max-width: 1199px) {
    .footer-top .container .col {
        width: 100% !important;

        &+.col {
            margin-top: 30px;
        }
    }
}

@media (max-width: 991px) {
    .form-search {
        margin-left: 60px;
    }

    .sidenav {
        transform: translate(-294px, 0);

        &.active {
            transform: translate(0);
        }
    }

    .nav-btn {
        display: block !important;
        position: absolute;
        left: 22px;
        top: 24px;
        background: transparent;
        color: $color-1;
        font-size: 30px;
    }

    .close-btn {
        display: block !important;
        position: absolute;
        right: 10px;
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 50%;
        top: 10px;
        color: $color-1;
        font-size: 16px;
    }

    .main {
        margin-left: 0;
        width: 100%;
    }

    .header {
        left: 0;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .form-search .fs-search {
        top: 15px;
    }

    .form-search .form-control {
        width: calc(50% - 1px) !important;
    }

    .form-search .select2-container {
        width: 50% !important;
    }

    .form-search .btn {
        margin-left: 0;
        width: 100%;
        margin-top: 10px;
    }

    .nav-btn {
        top: 30px;
        left: 10px;
    }

    .main {
        padding: 0 10px;
        margin-top: 136px;
    }

    .header {
        padding-left: 10px;
        padding-right: 10px;
    }

    .responsive-table {
        &__content {
            max-width: 100%;
            overflow-x: hidden;
        }

        // table {
        //     width: 700px;
        // }
    }

    .select2-dropdown--search {
        transform: translate(-50%, 0);
    }

    .footer-top .container .col {
        padding-left: 0;
    }

    .footer-top .container .col h3::before {
        display: none;
    }

    .footer-top .container .col:nth-child(2) ul {
        column-count: unset;
    }

    .footer-top .container .col+.col {
        margin-top: 20px;
    }

    .select2-dropdown--search .select2-results__option {
        padding: 5px 22px;
    }

    .menu__header a {
        font-size: 14px;
    }

}

@media (max-width: 340px) {
    .select2-dropdown--search {
        width: 320px !important;
    }
}
$font-1: 'Open Sans';
$color-1: #f38b39;
$lh: 1.313;

%clearfix {
    clear: both;

    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

%flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

%bgsvg {
    background-repeat: no-repeat;
    background-size: cover;
}

%flex-wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


.thumb-5x3 {
    padding-bottom: 60%;
}

.thumb-16x9 {
    padding-bottom: 56.25%;
}

// Spacing

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 20px;
}

@function px_to_rem($target, $context: $base-size) {
    @if $target==0 {
        @return 0
    }

    @return $target / $context+0rem;
}

@function calculateRem($size) {
    $remSize: $size / 16px;
    //Default font size on html element is 100%, equivalent to 16px;
    @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

$tablet-width: 768px;
$desktop-width: 992px;
$desktop-large-width: 1200px;

@mixin xs {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin not-xs {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin sm {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin not-sm {
    @media (max-width: #{$tablet-width - 1}) and (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-large-width - 1px}) {
        @content;
    }
}

@mixin not-md {
    @media (max-width: #{$desktop-width - 1}) and (min-width: #{$desktop-large-width}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$desktop-large-width}) {
        @content;
    }
}

@mixin not-lg {
    @media (max-width: #{$desktop-large-width - 1px}) {
        @content;
    }
}

.visible-lg {
    @include lg {
        display: block !important;
    }

    @include not-lg {
        display: none !important;
    }
}

.visible-md {
    @include md {
        display: block !important;
    }

    @include not-md {
        display: none !important;
    }
}

.visible-sm {
    @include sm {
        display: block !important;
    }

    @include not-sm {
        display: none !important;
    }
}

.visible-xs {
    @include xs {
        display: block !important;
    }

    @include not-xs {
        display: none !important;
    }
}

.hidden-lg {
    @include lg {
        display: none !important;
    }

    @include not-lg {
        display: block !important;
    }
}

.hidden-md {
    @include md {
        display: none !important;
    }

    @include not-md {
        display: block !important;
    }
}

.hidden-sm {
    @include sm {
        display: none !important;
    }

    @include not-sm {
        display: block !important;
    }
}

.hidden-xs {
    @include xs {
        display: none !important;
    }

    @include not-xs {
        display: block !important;
    }
}
* {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

button,
input[type="submit"] {
    border: none;
    cursor: pointer;
}

input[type="text"],
input[type="password"],
textarea {
    appearance: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: $font-1;

}

iframe {
    max-width: 100%;
}

html {
    font-size: 100%;

    @include sm {
        height: 100%;
    }

    @media (max-width: 1919px) {
        font-size: 80%;
    }
}

body {
    font-family: $font-1;
    line-height: 1.45;
    color: #252525;

    a {
        text-decoration: none;
        color: #252525;

    }
}

ul,
ol {
    list-style: none;
}

img {
    display: inline-block;
    max-width: 100%;
}

[class*="fs-"] {
    line-height: 0;
    display: inline-block;
}

.icon-svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

table {
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
}

figure {
    margin: 0;
}

.flex {
    display: flex;
}


:focus {
    outline: none;
}

.form-group {
    @extend %clearfix;
}

.width_common {
    float: left;
    width: 100%;
}

.clearfix {
    @extend %clearfix;
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 999;
}

#back2top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 45px;
    height: 45px;
    z-index: 10000;
    background-color: #e6e6e6;
    border: none;
    border-radius: 4px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 10001;
        @include fontSize(30px);
        padding-bottom: 5px;
        color: #777;
    }

    &.active {
        bottom: 30px;
        visibility: visible;
        opacity: 0.65;
    }

    &:hover {
        cursor: pointer;
        background-color: #e6e6e6;
        opacity: 1;
    }
}

.btn {
    min-width: 174px;
    height: 41px;
    display: inline-block;
    background: $color-1;
    color: #fff;
    @include fontSize(17px);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 20px;
}

// ==============
// SideNav
// =============

.sidenav {
    width: 294px;
    background: url(images/graphics/sidenav-bg.jpg) no-repeat center top/cover;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 99;

    &__logo {
        padding: 15px;
        display: block;
        text-align: center;
        border-bottom: 1px solid #252525;
    }

    &__menu {
        max-height: calc(100% - 123px);
        overflow-y: scroll;
        border-top: 1px solid #000000;

        &.scroll-wrapper {
            padding: 0 12px !important;
        }
    }

    &__notes {
        margin-top: 20px;

        .note {
            border: 1px solid #ff0000;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.1);
            color: #ff0000;
            @include fontSize(14px);
            font-size: 14px;
            padding: 10px;
            text-shadow: 1px 1px 8px rgba(255, 29, 29, .5);
            margin-bottom: 15px;

            p {
                &+p {
                    margin-top: 5px;
                }
            }
        }
    }

    .scrollbar-inner>.scroll-element.scroll-x {
        display: none;
    }


}

.menu {

    text-transform: uppercase;

    &__group {
        padding: 20px 0;

        &:not(:last-of-type) {
            border-bottom: 1px solid rgba(255, 255, 255, .12);
        }

    }

    &__header {
        display: flex;
        justify-content: space-between;

        h3 {
            font-weight: 400;
            width: calc(100% - 30px);

            a {
                display: flex;
                align-items: center;
            }

            i {
                color: $color-1;
                font-size: 16px;
                width: 30px;

                &.fs-phone,
                &.fs-iwatch,
                &.fs-pk-2,
                &.fs-pk-3 {
                    font-size: 20px;
                }

                &.fs-macbook {
                    font-size: 14px;
                }
            }

            span {
                width: calc(100% - 30px);
                line-height: 1.6;
            }
        }

        a {
            display: block;
            color: #FFF;
            @include fontSize(15px);
        }

        .accordion {
            cursor: pointer;
            display: inline-block;
            width: 20px;
            height: 20px;
            text-align: center;

            i {
                color: rgba(255, 255, 255, .6);
                transform: rotate(90deg);
            }
        }

        &.active {
            .accordion {
                i {
                    transform: rotate(180deg);
                    color: $color-1;
                }
            }
        }
    }

    &__sub {
        margin-left: 30px;
        margin-top: 10px;
        max-height: 0;
        transition: all .3s ease;
        overflow: hidden;
        opacity: 0;

        a {
            display: block;
            font-size: 12px;
            color: rgba(255, 255, 255, .6);
            padding: 5px 0;

            &:hover,
            &.active {
                color: #ff0000;
            }
        }

        &.active {
            max-height: none;
            opacity: 1;
        }
    }
}

.overplay-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    display: none;
    z-index: 98;
}

.header {
    width: calc(100% - 294px);
    position: fixed;
    left: 294px;
    top: 0;
    background: #efefef;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    z-index: 97;

    &__address {
        @include fontSize(18px);
        font-weight: 600;
        padding-left: 15px;
        min-width: 250px;
        line-height: 1.4;

        img {
            margin-right: 8px;
            float: left;
        }
    }

    @media (min-width: 1920px) {
        &__address {
            br {
                display: none;
            }
        }
    }

    @media (max-width: 1199px) {
        &__address {
            padding-left: 0;
            margin-top: 20px;

            br {
                display: none;
            }
        }
    }

    @include xs {
        &__address {
            display: none;
        }
    }
}

.form-search {
    max-width: 100%;

    .fs-search {
        position: absolute;
        left: 22px;
        color: rgba(0, 0, 0, 0.4);
    }

    .form-group {
        position: relative;
        display: flex;
        align-items: center;

        @include xs {
            flex-wrap: wrap;
            align-items: unset;
        }
    }

    .form-control {
        border-radius: 20px 0 0 20px;
        border: none;
        background-color: rgb(255, 255, 255);
        height: 41px;
        padding: 10px 15px 10px 44px;

        @media (min-width: 1600px) {
            width: 540px;
        }

        &::placeholder {
            color: #9f9f97;

            @media (max-width: 340px) {
                font-size: 13px;
            }
        }

        &:focus {
            &+.autocomplete-dropdown {
                max-height: none;
                opacity: 1;
                transition: all .3s;
            }
        }
    }

    .btn {
        margin-left: 10px;
    }

    .select2-container {
        width: 215px !important;

        .select2-selection {
            border-radius: 0 20px 20px 0 !important;
            border: none;
            border-left: 1px solid #dcdcdc;
            height: 41px;
            line-height: 41px;

            &__rendered {
                height: 41px;
                line-height: 41px;
            }

            &__arrow {
                top: 7px;
                right: 6px;

                b {
                    border-color: #d7d7d7 transparent transparent transparent
                }
            }
        }
    }

    .autocomplete-dropdown {
        position: absolute;
        left: 0;
        top: 41px;
        margin-top: 6px;
        width: 540px;
        max-width: 100%;
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.15);
        max-height: 0;
        opacity: 0;
        overflow: hidden;

        li {
            padding: 12px 25px;
            cursor: pointer;

            &:hover {
                background: #f4f4f4;
                color: #000;
                font-weight: 600;
            }
        }

    }
}

.select2-dropdown--search {
    width: 326px !important;
    border: none;
    margin-top: 6px;
    background: transparent;

    .select2-results__options {
        max-height: none !important;
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.15);
    }

    .select2-results__option {
        padding: 12px 22px;
    }

    .select2-results__option[aria-selected=true] {
        background-color: #f4f4f4;
        color: #000;
        font-weight: 600;
    }
}

.main {
    margin-left: 294px;
    width: calc(100% - 294px);
    margin-top: 85px;
    padding: 0 22px 0;
    background: #efefef;
}


.footer-top {
    margin-top: 50px;
    background: #FFFFFF url(images/graphics/footer-bg.png) no-repeat center/cover;
    padding: 50px 26px;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .col {
            padding-left: 35px;
            position: relative;

            h3 {
                @include fontSize(20px);
                margin-bottom: 20px;

                &::before {
                    content: "";
                    height: 3px;
                    width: 24px;
                    position: absolute;
                    left: 0;
                    top: 10px;
                    background: $color-1;
                }
            }

            ul {
                li {
                    padding: 15px 0;

                    a {
                        color: #000;
                        @include fontSize(14px);
                        display: flex;
                        align-items: center;

                        i {
                            width: 20px;
                            font-size: 11px;
                            color: $color-1;

                            &.circle {
                                &::before {
                                    content: "";
                                    width: 6px;
                                    height: 6px;
                                    border: 1px solid $color-1;
                                    border-radius: 50%;
                                    display: block;
                                }
                            }
                        }

                        &:hover {
                            color: $color-1;
                        }
                    }

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #e1e1e1;
                    }
                }
            }

            &:nth-child(2) {
                ul {

                    column-count: 2;
                    column-gap: 20px;
                    column-gap: 60px;

                    li {
                        break-inside: avoid-column;
                    }
                }
            }
        }
    }
}

.footer-bottom {
    background: $color-1;
    color: #FFF;
    padding: 22px 26px;
    @include fontSize(13px);
    text-align: center;
}
@import "scss/normalize.scss";
@import "scss/owl.scss";
@import "scss/scrollbar.scss";
@import "scss/select2.scss";
@import "scss/magnific-popup.scss";

@import "scss/var.scss";
@import "scss/general.scss";
@import "scss/component.scss";

@import "scss/responsive.scss";

@import "fonts/iconfont/iconfont.css";